/**
 * MinersFactory | website footer component
 */

import React from "react"
import { Link } from "gatsby"

import TwitterIcon from "./twittericon"
import InstagramIcon from "./instagramicon"
import DiscordIcon from "./discordicon"

const Footer = () => (
    <footer>
        <div className="row">
            <div className="col-sm-12 col-md-4">
                <div>
                    <p>
                        &copy; {new Date().getFullYear()} MinersFactory. Alle
                        Rechte vorbehalten.
                    </p>
                    <p>
                        <small>
                            KEIN OFFIZIELLES MINECRAFT-PRODUKT. NICHT VON MOJANG
                            GENEHMIGT ODER MIT MOJANG VERBUNDEN.
                        </small>
                    </p>
                </div>
            </div>
            <div className="col-sm-12 col-md-4">
                <div>
                    <Link
                        to="/impressum/"
                        className="footer-link"
                        activeClassName="footer-active"
                    >
                        Impressum
                    </Link>
                    <span> • </span>
                    <Link
                        to="/datenschutz/"
                        className="footer-link"
                        activeClassName="footer-active"
                    >
                        Datenschutzerklärung
                    </Link>
                </div>
            </div>
            <div className="col-sm-12 col-md-4">
                <div>
                    <TwitterIcon />
                    <InstagramIcon />
                    <DiscordIcon />
                </div>
            </div>
        </div>
    </footer>
)

export default Footer
