/**
 * MinersFactory | instagram footer icon
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const InstagramIcon = () => {
    const data = useStaticQuery(graphql`
        query {
            instagramLogo: file(
                relativePath: { eq: "socialicons/instagram.png" }
            ) {
                childImageSharp {
                    fixed(width: 48, height: 48) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return (
        <a
            href="https://www.instagram.com/minersfactory/"
            target="_blank"
            rel="noopener noreferrer"
        >
            <Img
                fixed={data.instagramLogo.childImageSharp.fixed}
                className="insta-marketing"
            />
        </a>
    )
}

export default InstagramIcon
