/**
 * MinersFactory | sub page template
 */

import React from "react"
import PropTypes from "prop-types"

import Main from "./../templates/main"

import "./../components/page.css"

const SubPage = ({ children }) => (
    <Main>
        <div className="subp-content">{children}</div>
    </Main>
)

SubPage.propTypes = {
    children: PropTypes.node.isRequired,
}

export default SubPage
