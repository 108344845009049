/**
 * MinersFactory | team member protrait
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const TeamPortrait = ({ imgName }) => {
    const data = useStaticQuery(graphql`
        query {
            defaultFallbPortr: file(
                relativePath: { eq: "team/placeholder.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 256) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            teamPortrs: allFile(filter: { relativeDirectory: { eq: "team" } }) {
                edges {
                    node {
                        name
                        childImageSharp {
                            fluid(maxWidth: 256) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `)

    let imgFluid = null

    data.teamPortrs.edges.map(item => {
        const name = item.node.name
        const fluid = item.node.childImageSharp.fluid

        if (name === imgName) {
            imgFluid = fluid
        }
    })

    if (imgFluid === null) {
        return (
            <Img
                fluid={data.defaultFallbPortr.childImageSharp.fluid}
                className="team-portrait"
            />
        )
    }

    return <Img fluid={imgFluid} className="team-portrait" />
}

TeamPortrait.propTypes = {
    imgName: PropTypes.string.isRequired,
}

export default TeamPortrait
