/**
 * MinersFactory | twitter footer icon
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const TwitterIcon = () => {
    const data = useStaticQuery(graphql`
        query {
            twitterLogo: file(relativePath: { eq: "socialicons/twitter.png" }) {
                childImageSharp {
                    fixed(width: 48, height: 48) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return (
        <a
            href="https://twitter.com/minersfactory"
            target="_blank"
            rel="noopener noreferrer"
        >
            <Img
                fixed={data.twitterLogo.childImageSharp.fixed}
                className="twitter-marketing"
            />
        </a>
    )
}

export default TwitterIcon
