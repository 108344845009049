/**
 * MinersFactory | discord footer icon
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const DiscordIcon = () => {
    const data = useStaticQuery(graphql`
        query {
            discordLogo: file(relativePath: { eq: "socialicons/discord.png" }) {
                childImageSharp {
                    fixed(width: 141, height: 48) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return (
        <a
            href="https://discord.gg/yEmZmzu"
            target="_blank"
            rel="noopener noreferrer"
        >
            <Img
                fixed={data.discordLogo.childImageSharp.fixed}
                className="discord-marketing"
            />
        </a>
    )
}

export default DiscordIcon
