/**
 * MinersFactory | main template
 */

import React from "react"
import PropTypes from "prop-types"

import Navigation from "./../components/navigation"
import Footer from "./../components/footer"

import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./../components/main.css"

const Main = ({ children }) => (
    <>
        <Navigation />
        <main>{children}</main>
        <Footer />
    </>
)

Main.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Main
